<template>
    <v-navigation-drawer
        v-model="mDrawer"
        app
        clipped
        hide-overlay
        right
        style="height: 100%;"
        temporary
        width="400px">
        <template v-slot:prepend>
            <v-toolbar dense>
                <v-btn v-if="currentCategory == null" large @click="closeDrawer" icon>
                    <v-icon large>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn v-else large @click="currentCategory = null" icon>
                    <v-icon large>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>{{( currentCategory != null ? currentCategory.categoryName : 'Products' )}}</v-toolbar-title>
                <v-spacer />
                <v-btn class="mx-1" color="primary" @click="closeAndSave"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
            </v-toolbar>
        </template>
        <v-list v-if="mUseCategories && currentCategory == null">
            <v-list-item v-for="(category, ind) in categories" :key="ind" dense @click="selectCategory(category)">
                <v-list-item-avatar rounded>
                    <v-img :src="productLogoURL(category.id)" class="my-auto">
                        <template v-slot:placeholder><v-icon class="primary--text" size="40">mdi-shape-outline</v-icon></template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ category.categoryName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ category.categoryDescription }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list v-else-if="isLengthyArray(filteredOrderItems)" dense>
            <template v-for="(orderItem, index) in filteredOrderItems">
                <Order-Line-Item 
                    :canEdit="isEditing"
                    :item="orderItem"
                    :showImages="showImages"
                    :key="index"
                    @change="updateOrder" />
                <v-divider :key="'d' + orderItem.productID" />
            </template>
        </v-list>
        <v-container v-else>
            Nothing Available To This Customer
        </v-container>
        <v-list dense>
            <v-list-item v-if="copiedOrder != null" dense>
                <v-list-item-content>SUBTOTAL</v-list-item-content>
                <v-list-item-action>{{ copiedOrder.subTotal | toCurrency }}</v-list-item-action>
            </v-list-item>
            <v-list-item v-if="copiedOrder != null" >
                <v-list-item-content>GST</v-list-item-content>
                <v-list-item-action>{{ copiedOrder.taxTotal | toCurrency }}</v-list-item-action>
            </v-list-item>
            <v-list-item v-if="copiedOrder != null" >
                <v-list-item-content>TOTAL</v-list-item-content>
                <v-list-item-action>{{ copiedOrder.amountTotal | toCurrency }}</v-list-item-action>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { calculateAutoItems, loadOrderItems, updateOrder } from '~tools/helpers.js';

export default {
    name: 'BT-Order-Items-Drawer',
    components: {
        OrderLineItem: () => import('~home/purchase-orders/Ordering-Line-Item.vue')
    },
    data: function() {
        return {
            allOrderItems: [],
            autoOrderItems: [],
            categories: [],
            copiedOrder: null,
            currentCategory: null,
            isLoaded: false,
            isSave: false,
            mDrawer: false,
            mUseCategories: false,
            oldAutoOrderItems: [] //for trying to retain ids and rowversions when editing (not creating)
        }
    },
    props: {
        drawerToggle: {
            type: Boolean,
            default: false
        },
        height: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Products'
        },
        order: null,
        policy: null,
        refreshToggle: {
            type: Boolean,
            default: false
        },
        showImages: {
            type: Boolean,
            default: false
        },
        useCategories: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.mUseCategories = this.useCategories;
    },
    watch: {
        drawerToggle() {
            this.mDrawer = true;
        },
        mDrawer(val) {
            if (val) {
                if (!this.isLoaded) {
                    this.getOrderItems();
                }
            }
            else {
                this.save();
            }
        },
        refreshToggle() {
            this.updateOrder();
        }
    },
    computed: {
        filteredOrderItems() {
            if (!this.isLengthyArray(this.categories) || !this.mUseCategories) {
                return this.allOrderItems;
            }
            else if (this.currentCategory != null) {
                if (this.currentCategory.id == 'other') {
                    return this.allOrderItems.filter(z => z.data.product == null || z.data.product.categoryID == null);
                }
                else {
                    return this.allOrderItems.filter(z => z.data.product != null && z.data.product.categoryID == this.currentCategory.id);
                }
            }

            return [];
        }
    },
    methods: {
        closeAndSave() {
            this.isSave = true;
            this.mDrawer = false;
        },
        closeDrawer() {
            this.isSave = false;
            this.mDrawer = false;
        },
        async getOrderItems() {
            if (this.policy == null || this.order == null) {
                return;
            }

            this.copiedOrder = this.copyDeep(this.order);

            this.oldAutoOrderItems = this.copiedOrder.orderItems.filter(x => x.productID == null);

            //remove auto items in copied order
            this.copiedOrder.orderItems = this.copiedOrder.orderItems.filter(x => x.productID != null);

            //sort?
            this.allOrderItems = loadOrderItems(this.copiedOrder, this.policy);

            this.autoOrderItems = calculateAutoItems(this.policy, this.allOrderItems, this.copiedOrder);

            this.updateOrder();

            if (this.mUseCategories) {
                var categoryIDs = [...new Set(this.policy.orderItems.filter(z => z.product != null && z.product.categoryID != null).map(x => x.product.categoryID))];
                if (this.isLengthyArray(categoryIDs)) {
                    this.categories = await this.$BlitzIt.store.getAll('public-product-categories', { ids: categoryIDs.toString() });

                    if (this.isLengthyArray(this.categories)) {
                        if (this.policy.orderItems.some(z => z.product == null || z.product.categoryID == null)) {
                            this.categories.push({
                                id: 'other',
                                categoryName: 'Other'
                            });
                        }
                    }
                }
                else {
                    this.mUseCategories = false;
                }
            }

            return this.allOrderItems;
        },
        save() {
            //attempt to reconcile old auto order item ids and row versions
            this.copiedOrder.orderItems.forEach(x => {
                if (x.productID == null && x.lineItemCode != null) {
                    var oldAutoItem = this.oldAutoOrderItems.find(y => y.lineItemCode == x.lineItemCode);
                    if (oldAutoItem != null) {
                        x.id = oldAutoItem.id;
                        x.rowVersion = oldAutoItem.rowVersion;
                    }
                }
            })

            this.$emit(this.isSave ? 'okAndSave' : 'ok', this.copiedOrder ); //{ order: this.copiedOrder, autoOrderItems: this.autoOrderItems });
        },
        selectCategory(category) {
            this.currentCategory = category;
        },
        updateOrder() {
            this.autoOrderItems = calculateAutoItems(this.policy, this.allOrderItems, this.copiedOrder);
            
            updateOrder(
                this.copiedOrder,
                this.policy,
                this.allOrderItems,
                this.autoOrderItems);

            this.$forceUpdate();
        }
    }
}
</script>